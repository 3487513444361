.faq-page {
  min-height: 200px;
  padding: 100px 175px 138px 175px;
}
.faq-button {
  background-color: #fff;
  border-radius: 4px;
  border-radius: 4px;
  color: #202020;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.16px;
  padding: 16px 24px;
  font-family: Tajawal;
  border: 2px solid #000;
  cursor: pointer;
  transition: 300ms;
}
.active-faq {
  background-color: #0a1730;
  color: #ffff;
}
.pagination {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 65px;
}
@media (max-width: 1278px) {
  .faq-page {
    padding: 100px 7% 138px 7%;
  }
}
@media (max-width: 498px) {
  .faq-page {
    padding: 100px 4% 138px 4%;
  }
  .pagination {
    justify-content: center;
  }
  .faq-button {
    padding: 10px 16px;
    font-size: 13px;
  }
}
