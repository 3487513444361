@font-face {
  font-family: ibm-ar-font;
  src: url("./assets/font/IBMPlexArabic-Text.ttf");
}
@font-face {
  font-family: numbers-font;
  src: url("./assets/font/GE-Heritage-Two-Medium_A.otf");
}

* {
  font-family: ibm-ar-font !important;
}
.custom-datepicker-input {
  height: 55px;
  width: 100%;
  padding: 12.5px 14px;
  border-radius: 5px;
  border: 1px solid #dadada;
  font-size: 16px;
  direction: rtl;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .custom-datepicker-input {
    height: 60px;
  }
}

.custom-datepicker-input:focus {
  outline: none;
  border-color: #6c63ff;
}

/* Add this CSS to your stylesheet */
.s-comp-footer-contact-form-input-cont {
  background: #ffffff;
  border-radius: 4px;
  padding: 4px 6px;
  display: flex;
  flex-direction: row;
}

@media (max-width: 501px) {
  .s-comp-footer-contact-form-input-cont {
    flex-direction: column;
  }
}

.s-comp-footer-contact-form-input-cont input {
  background: transparent;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #808080;
  padding: 12px 14px 12px;
  flex: 1;
}

@media (max-width: 501px) {
  .s-comp-footer-contact-form-input-cont input {
    padding: 12px 14px 19px;
  }
}

.s-comp-footer-contact-form-input-cont button {
  background: #0a1730;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #ffffff;
  padding: 16px 0;
  width: 190px;
  outline: 0;
  border: 0;
  cursor: pointer;
}

@media (max-width: 501px) {
  .s-comp-footer-contact-form-input-cont button {
    width: 100%;
  }
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-input {
  height: 50px !important;
  border-radius: 5px !important;
  border: 1px solid #dadada !important;
  padding: 0 14px !important;
  width: 100% !important;
}
.rmdp-input:focus {
  outline: none !important;
  border-color: #6c63ff !important;
}
@media (min-width: 768px) {
  .rmdp-input {
    height: 60px !important;
  }
}
