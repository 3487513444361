.bg-black {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  cursor: pointer;
}

.alert-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  position: fixed;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  padding: 20px 30px;
  border-radius: 15px;
  cursor: auto;
  animation: popup 1s ease;
}

@keyframes popup {
  0% {
    opacity: 0;
    top: 55%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

.bg-check {
  border-radius: 62px;
  background: #f9f9f9;
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-parent h3 {
  margin: 15px 0;
  color: #1b1b1b;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
}

.alert-parent p {
  margin: 0 0 20px;
  text-align: center;
  font-weight: 500;
  color: rgba(27, 27, 27, 0.7);
  font-size: 16px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .alert-parent {
    padding: 15px 20px;
  }
  .alert-parent h3 {
    font-size: 20px;
  }
  .alert-parent p {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .alert-parent h3 {
    font-size: 18px;
  }
  .alert-parent p {
    font-size: 13px;
  }
}
