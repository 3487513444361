.advantages-component {
  margin-top: 130px;
}

.advantages-component .header {
  font-family: Tajawal;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  color: #202020;
}
