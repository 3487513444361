.q-parent {
  width: 100% !important;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.answer {
  padding: 0px 0 10px 0 !important;
}
@media (max-width: 498px) {
  .q-parent {
    font-size: 18px !important;
    justify-content: space-between;
  }
  .q-parent svg {
    width: 18px;
    height: 10px;
  }
}
.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-header a {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #202020;
  border-bottom: 1px solid #202020;
  padding-bottom: 6px;
}
