.mobile-nav {
  display: none;
}
.bars {
  display: none;
}
@media (max-width: 1279px) {
  .mobile-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    z-index: 100;
    width: 100%;
    height: 100vh;
    top: 70px;
    border-radius: 10px;
    padding: 40px 20px 0 0px;
    transition: 300ms;
  }
  .none {
    left: 1500px;
  }
  .block {
    left: 0;
  }
  .mobile-nav ul a {
    padding: 12px 0;
  }
  .bars {
    display: block;
  }
}
