.about-sec {
  margin: 58px 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-sec h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  color: #202020;
  margin-bottom: 27px;
}

.about-sec p {
  font-weight: 500;
  color: rgba(32, 32, 32, 0.7);
  font-size: 19px;
  line-height: 1.8;
  margin-bottom: 27px;
}

@media (max-width: 1024px) {
  .about-sec {
    margin: 58px 4%;
  }

  .about-sec h2 {
    font-size: 32px;
    line-height: 1.3;
  }

  .about-sec p {
    font-size: 18px;
    line-height: 1.6;
  }
}

@media (max-width: 767px) {
  .about-sec {
    margin: 40px 3%;
  }

  .about-sec h2 {
    font-size: 28px;
    line-height: 1.3;
  }

  .about-sec p {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 480px) {
  .about-sec {
    margin: 30px 2%;
  }

  .about-sec h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .about-sec p {
    font-size: 13px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}
