.about-page {
  padding: 100px;
}
.about-page .categories {
  margin-bottom: 40px;
}
.about-page .categories button {
  margin: 0 8px;
}
.about-page h1 {
  font-size: 45px;
  color: rgb(32, 32, 32);
  text-align: center;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .about-page h1 {
    font-size: 38px;
  }
}

@media (max-width: 768px) {
  .about-page h1 {
    font-size: 38px;
    margin-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .about-page {
    padding: 60px 60px;
  }
}

@media (max-width: 768px) {
  .about-page {
    padding: 40px 30px;
  }
}

@media (max-width: 480px) {
  .about-page {
    padding: 30px 4% 50px;
  }
  .about-page .categories button {
    margin: 5px 0px;
    width: 100%;
  }
  .about-page .categories {
    margin-bottom: 30px;
  }
}

.main-content {
  background-color: #f9f9f9;
  padding: 40px 60px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.main-content h2 {
  color: #333;
  margin-bottom: 12px;
}

.main-content ol,
.main-content ul {
  margin-left: 20px;
  margin-bottom: 15px;
}

.main-content li {
  margin-bottom: 8px;
}

.main-content p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.main-content div {
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .privay-par {
    padding: 50px 60px 5%;
  }

  .main-content {
    padding: 30px 40px;
  }
}

@media (max-width: 768px) {
  .privay-par {
    padding: 40px 30px 5%;
  }

  .main-content {
    padding: 20px 30px;
  }
}

@media (max-width: 480px) {
  .privay-par {
    padding: 30px 15px 5%;
  }

  .main-content {
    padding: 15px 20px;
  }

  .main-content h2 {
    font-size: 1.2em;
  }

  .main-content p,
  .main-content li {
    font-size: 0.9em;
  }
}
